import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://github.com/HumbertoIsraelLV" target="_blank">
      <div>
        <BsGithub />
      </div>
    </a>
    <a href="https://www.linkedin.com/in/humberto-israel-lopez-vela/" target="_blank">
      <div>
          <BsLinkedin />
      </div>
    </a>
  </div>
);

export default SocialMedia;