import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    }
};

export const Navigation = ({menuNames, toggle}: any) => (
  <motion.ul variants={variants}>
    {menuNames.map((name: any) => (
      <MenuItem name={name} key={name} toggle={toggle} />
    ))}
  </motion.ul>
);