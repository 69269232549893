import React, { useRef } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion, useCycle } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';
import { useDimensions } from '../../hooks/useDimensions';
import { MenuToggle } from './MenuToogle';
import { Navigation } from './Navigation';
import { SocialMedia } from '..';

const menuNames = ['home', 'about', 'showcase', 'skills', 'trajectory'];


const sideMenu = {
  open: {
    width: '15rem',
    height: '80rem',
  },
  closed: {
    width: '3.2rem',
    height: '3.2rem',
  }
};
const sidebar = {
  open: () => ({
    // clipPath: `circle(${height * 2 + 200}px at 2rem 2rem)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    },
    width: '15rem',
    height: '100rem',
  }),
  closed: {
    // clipPath: "circle(1rem at 2rem 1.7rem)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    },
    width: '3.2rem',
    height: '3.2rem',
  }
};


// const variants = {
//   open: { opacity: 1, x: 0 },
//   closed: { opacity: 0, x: "100%" },
// }

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1, velocity: -100 },
      delay: 0.5,
    },
    width: '100%',
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1 },
    },
    width: '0%',
  }
};
// const variants = {
//   open: {
//     y: 0,
//     width: '20rem',
//     height: '10rem',
//     opacity: 1,
//     transition: {
//       y: { stiffness: 1000, velocity: -100 }
//     }
//   },
//   closed: {
//     y: 50,
//     width: '2rem',
//     height: '2rem',
//     opacity: 0,
//     transition: {
//       y: { stiffness: 1000 }
//     }
//   }
// };


const Navbar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  // var isOpen: boolean=false; 
  // var isRealOpen: boolean=false; 
  // const [isRealOpen, toggleRealOpen] = useCycle(false, true);
  
  // const toggleRealOpen = async () => {
  //   if(isRealOpen===false){
  //     isRealOpen=true;
  //     await delay(1000);
  //     isOpen=true;
  //   }else{
  //     isOpen=false;
  //     await delay(1000);
  //     isRealOpen=false;
  //   }
  //   console.log(`real: ${isRealOpen}/ notReal: ${isOpen}`);
    
  // }
  // const containerRef = useRef(null);
  // const { height } = useDimensions(containerRef);


  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="#home">
          <span>humberto-lopez.dev</span>
        </a>
      </div>
      <ul className="app__navbar-links">
        {menuNames.map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      {/* {isRealOpen && */}
      <motion.nav
        className="app__side-nav"
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={sideMenu}
        // ref={containerRef}
        >

          <>
            <motion.div
              className="background"
              variants={sidebar}
            />
            <motion.div
              variants={variants}
            >
              <SocialMedia/>
            </motion.div>
            <Navigation menuNames={menuNames} toggle={() => toggleOpen()}/>
<MenuToggle toggle={() => toggleOpen()} />
          </>


      </motion.nav>
        {/* } */}
{/* <MenuToggle toggle={() => toggleRealOpen()} /> */}


      {/* <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.nav
            initial={false}
            animate={toggle ? "open" : "closed"}
            variants={variants}
            // whileInView={{ x: [300, 0] }}
            // transition={{ duration: 1, ease: 'easeOut' }}
            // style={{display: (toggle)?'flex':'none'}}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {menuItems.map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.nav>
        )}
      </div> */}
    </nav>
  );
};

export default Navbar;