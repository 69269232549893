import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Work.scss';

const Work = () => {
  const [works, setWorks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesMap, setCategoriesMap] = useState(new Map());
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2000 },
      items: 3,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 2000, min: 820 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 820, min: 640 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const customRightArrow = () => {
    return(
      <div style={{ backgroundColor: 'black', height: '40px', width: '40px'}}>
        Right
      </div>
    );
  }
  const customLeftArrow = () => {
    return(
      <div style={{ backgroundColor: 'black', height: '40px', width: '40px'}}>
        Left
      </div>
    );
  }

  useEffect(() => {
    const query = '*[_type == "works"]';
    const categoriesQuery = '*[_type == "portfolioCategories"]';

    client.fetch(query).then((data) => {
      
      //TODO: This filter should not be necessary as it is filtering deleted data
      //in sanity, but the data stills appearing.
      const filteredData = data.filter((element: any) => {
        return element.title!=="Personal Web";
      });
      
      setWorks(filteredData);
      setFilterWork(filteredData);
    });

    client.fetch(categoriesQuery).then((data) => {
      data.sort((a: any, b: any) => {
        return b.position - a.position;
      });
      //TODO: This filter should not be necessary as it is filtering deleted data
      //in sanity, but the data stills appearing.
      const filteredData = data.filter((element: any) => {
        return element.name!=="React";
      });
      var categoriesMapAux = new Map();
      for (const category of filteredData) {
        categoriesMapAux.set(category._id, category.name);
      }
      setCategoriesMap(categoriesMapAux);
      setCategories(filteredData);
    });
  }, []);

  const handleWorkFilter = (item: any) => {
    setActiveFilter(item.name);
    // setAnimateCard([{ y: 100, opacity: 0 }]);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      // setAnimateCard([{ y: 0, opacity: 1 }]);
      setAnimateCard({ y: 0, opacity: 1 });

      if (item.name === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work: any) => {
          for (const category of work.categories) {
            // console.log(`category: ${category._ref}`);
            // console.log(`item: ${item._id}`);
            if(category._ref === item._id){
              return true;
            }
          }
          return false;
        }));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">My <span>Showcase</span></h2>

      {/* <div className="app__work-filter">
        {[...categories, {name: 'All'}].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${activeFilter === item.name ? 'item-active' : ''}`}
          >
            {item.name}
          </div>
        ))}
      </div> */}

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.length===0  && 
          <h3>Nothing to show...</h3>
        }
        <Carousel 
          autoPlay={false}
          containerClass="carousel-container"
          draggable={false}
          itemClass="carousel-item-container"
          minimumTouchDrag={10}
          responsive={responsive}
          shouldResetAutoplay={false}
        >

          {filterWork.map((work: any, index) => (


            <div className="app__work-item app__flex" key={index}>
              <h4 className="bold-text">{work.title}</h4>
              <div
                className="app__work-img"
              >
                <img src={urlFor(work.imgUrl) as unknown as string} alt={work.name} />

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  // whileTap={{ opacity: [0, 1] }}
                  transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                  className="app__work-hover app__flex"
                >
                  <a href={work.projectLink} target="_blank" rel="noreferrer">

                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.90] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                  </a>
                  {/* <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.90] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillGithub />
                    </motion.div>
                  </a> */}
                </motion.div>
              </div>

              <div className="app__work-content app__flex">
                
                {/* <div className="app__work-tag-container">
                  {work.categories.map((category: any, index: any) => {
                    return (
                      <div className="app__work-tag">
                        <p>{categoriesMap.get(category._ref)}</p>
                      </div>
                    )
                  })}
                </div> */}
                <p className="p-text">{work.description}</p>

                {/* {(work.categories!=null)
                  ?<div className="app__work-tag app__flex">
                    <p className="p-text">{categoriesMap.get(work.categories[0]._ref)}</p>
                  </div>
                  :<></>
                } */}
                
              </div>
            </div>
          ))}
        </Carousel>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'showcase',
  'app__primarybg',
);