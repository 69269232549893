import React from 'react';

import './App.scss'
import { Navbar } from './components';
import { About, Education, Footer, Header, Skills, Testimonial, Work } from './container';

const App = () => {
  return (
    <div className="app">
        <Navbar/>
        <Header/>
        <About/>
        <Work/>
        <Skills/>
        <Education/>
        <Testimonial/>
        <Footer/>
    </div>
  );
}

export default App;