import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';
import { getShortLastUse } from '../../utils';

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';
    const languagesQuery = '*[_type == "languages"]';

    client.fetch(query).then((data) => {
      data.sort((a: any, b: any) => {
        const aFirstYear = parseInt(a.year.substring(0,5));
        const bFirstYear = parseInt(b.year.substring(0,5));
        return bFirstYear - aFirstYear;
      });
      setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      data.sort((a: any, b: any) => {
        // const aFirstYear = parseFloat(a.years);
        // const bFirstYear = parseFloat(b.years);
        // return bFirstYear - aFirstYear;
        const aLastUse = new Date(a.lastUse);
        const bLastUse = new Date(b.lastUse);
        return bLastUse.getTime() - aLastUse.getTime();
      });
      console.log(data);
      setSkills(data);
      
    });
    
    client.fetch(languagesQuery).then((data) => {
      setLanguages(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          <h3>Programming</h3>
          {skills.map((skill: any) => (
            <>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
              data-tip
              data-for={skill.name}
            >
              <div
                className="app__flex"
              >
                <img src={urlFor(skill.icon) as unknown as string} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
            <ReactTooltip
              id={skill.name}
              effect="solid"
              arrowColor="#212529"
              className="skills-tooltip"
            >
              {`Experience: 
                ${(skill.years.split(".")[0]!=="0")
                  ?(skill.years.split(".")[0]==="1")
                    ? `${skill.years.split(".")[0]} year` 
                    : `${skill.years.split(".")[0]} years`
                  :''
                }${(skill.years.split(".")[1]!==undefined)
                  ? (skill.years.split(".")[1]==="1")
                    ? ` ${skill.years.split(".")[1]} month`
                    : ` ${skill.years.split(".")[1]} months`
                  :`` 
                }
              `}
              <br/>
              {`Last use: ${getShortLastUse(skill.lastUse)}`}
            </ReactTooltip>
            </>
          ))}
        </motion.div>
        <motion.div className="app__skills-languages-list">
          <h3>Languages</h3>
          {languages.map((language: any) => (
            <>
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={language.name}
              data-tip
              data-for={language.name}
            >
              <div
                className="app__flex"
              >
                <img src={urlFor(language.icon) as unknown as string} alt={language.name} />
              </div>
              <p className="p-text">{language.name}</p>
            </motion.div>
            <ReactTooltip
              id={language.name}
              effect="solid"
              arrowColor="#212529"
              className="skills-tooltip"
            >
              {language.level}
            </ReactTooltip>
            </>
          ))}
        </motion.div>
        {/* <div className="app__skills-exp">
          {experiences.map((experience: any) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work: any) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#212529"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);