import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Education.scss';
import moment from 'moment';

const Education = () => {
  const [experiences, setExperiences] = useState([]);
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const certificationsQuery = '*[_type == "certifications"]';

    client.fetch(query).then((data) => {
      data.sort((a: any, b: any) => {
        const aFirstYear = parseInt(a.year.substring(0,5));
        const bFirstYear = parseInt(b.year.substring(0,5));
        return bFirstYear - aFirstYear;
      });
      //TODO: 2022-Present is shown twice from sanity
      var presentCount=0;
      var presentCountLastIndex=0;
      for (const element in data) {
        //@ts-ignore
        if(data[element].year==="2022 - present"){
          presentCount=presentCount+1;
          //@ts-ignore
          presentCountLastIndex=element;
        }
        if(presentCount>=2){
          delete data[presentCountLastIndex]; 
        }
      }
      //END TODO: 2022-Present is shown twice from sanity
      
      setExperiences(data);
      
    });
    client.fetch(certificationsQuery).then((data) => {
      data.sort((a: any, b: any) => {
        const aDate: any = new Date(a.date); 
        const bDate: any = new Date(b.date); 
        return bDate - aDate;
      });
      setCertifications(data);
    });

    
  }, []);

  return (
    <>
      <h2 className="head-text"><span>Trajectory</span> & Education</h2>

      <div className="app__education-container">
        <div className="app__skills-exp" >
          {experiences.map((experience: any) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work: any) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#403f4f"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
        <div className="app__skills-certifications" >
          {certifications.map((certification: any) => (
            <motion.div
              className="app__skills-certifications-item"
              key={certification.name}
            >
              <a 
                href={certification.url.includes('http')
                  ?certification.url
                  :'javascript: void(0)'
                } 
                target={certification.url.includes('http')
                  ?'_blank'
                  :''
                }
                style={certification.url.includes('http')
                  ?{ cursor: 'pointer' }
                  :{ cursor: 'default' }
                }
              >

              <motion.div className="app__skills-certifications-works">
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-certifications-work"
                      data-tip
                      data-for={certification.name}
                      key={certification.name}
                    >
                      <h4 className="bold-text">{certification.name}</h4>
                      {/* <div className="app__skills-certifications-year">
                        <p className="bold-text">{experience.year}</p>
                      </div> */}
                      {/* <p className="p-text">{experience.year}</p> */}
                      <p className="p-text">{`${
                        moment(certification.date).format('MMM, YYYY')} / ${certification.company} / ${certification.id}`}</p>
                    </motion.div>
              </motion.div>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Education, 'app__education'),
  'trajectory',
  'app__primarybg',
);