import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Testimonial.scss';
import Carousel from 'react-multi-carousel';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 820 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 820, min: 640 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([] as any);
  const [brands, setBrands] = useState([]);

  const handleClick = (index: any) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      data.sort((a: any, b: any) => {
        return a.importance - b.importance;
      });
      setTestimonials(data);
    });
    

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);
  
  return (
    <>
      <h2 className="head-text">Recommendations</h2>
      {testimonials.length && (
    //     <>
        
    //       <div className="app__testimonial-item app__flex">
    //         <img src={urlFor(testimonials[currentIndex].imageurl) as unknown as string} alt={testimonials[currentIndex].name} />
    //         <div className="app__testimonial-content">
    //           <div>
    //             <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
    //             <h5 className="p-text">{testimonials[currentIndex].company}</h5>
    //           </div>
    //           <p className="p-text">{testimonials[currentIndex].feedback}</p>
    //         </div>
    //       </div>

    //       <div className="app__testimonial-btns app__flex">
    //         <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
    //           <HiChevronLeft />
    //         </div>

    //         <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
    //           <HiChevronRight />
    //         </div>
    //       </div>
    //     </>
    <Carousel 
      autoPlay={false}
      containerClass="carousel-container"
      draggable={false}
      itemClass="carousel-item-container"
      minimumTouchDrag={10}
      responsive={responsive}
      shouldResetAutoplay={false}
      slidesToSlide={1}
    >
      {testimonials.map((testimonial: any, index: any) => 
        <div className="app__testimonial-item app__flex">
                 <img src={urlFor(testimonial.imageurl) as unknown as string} alt={testimonial.name} />
                 <div className="app__testimonial-content">
                   <a href={testimonial.url} target='_blank'>
                     <h4 className="bold-text">{testimonial.name}</h4>
                     <h5 className="p-text">{testimonial.company}</h5>
                   </a>
                   <p className="p-text">{testimonial.feedback}</p>
                 </div>
               </div>
      
      )}
    </Carousel>
      )}

    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__whitebg',
);