import moment from "moment";

export const getShortLastUse = (date: string) => {
    const dateParsed = new Date(date);
    const diffDays = Math.ceil((Math.abs(Date.now() - dateParsed.getTime())) / (1000 * 60 * 60 * 24));
    if (diffDays<30){
        return 'recently';
    }
    if (diffDays<365) {
        return `${Math.trunc(diffDays/30)} months ago`;
    }
    return `${Math.trunc(diffDays/365)} ${(Math.trunc(diffDays/365))===1?'year':'years'} ago`;
}