import * as React from "react";
import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
        y: { stiffness: 1 },
    },
  }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ name, toggle }: any) => {
//   const style = { border: `2px solid ${colors[i]}` };
  return (
    <motion.li
      variants={variants}
      whileTap={{ scale: 0.95 }}
    >
        <a href={`#${name}`} onClick={toggle}>{name}</a>
      {/* <div className="icon-placeholder" style={style} /> */}
      {/* <div className="text-placeholder" style={style} >
      </div> */}
    </motion.li>
  );
};
