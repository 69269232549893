import React, { useEffect, useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ 
    subject: '', 
    email: '', 
    message: '', 
  });
  const [subjectFocused, setSubjectFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);
  
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    console.log(Object.fromEntries(data.entries()));
    
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.subject,
      email: formData.email,
      message: formData.message,
    };

    // setTimeout(()=>{
    //   setLoading(false);
    //   setIsFormSubmitted(true);
    // }, 2000);
    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
        setSubjectFocused(false);
        setEmailFocused(false);
        setMessageFocused(false);
        setFormData({
          subject: '', 
          email: '', 
          message: '',
        });
      })
      .catch((err) => console.log(err));
    
  };

  return (
    <>
      {/* {!isFormSubmitted ? (
        <div className='app__footer'>
        <h2 className="head-text">Shoot me a message & talk with me</h2>
          <form className="app__footer-form app__flex" onSubmit={handleSubmit}>
            <div className="app__flex">
                <input
                  className="p-text" 
                  type="text" 
                  placeholder="Your Subject" 
                  name="subject" 
                  value={formData.subject} 
                  onChange={handleChangeInput} 
                  required={true}
                  pattern="^.{1,60}$"
                  onBlur={()=>setSubjectFocused(true)}
                  //@ts-ignore
                  focused={subjectFocused.toString()}
                  />

              <p className="form-error">
                {"Subject length should be 1-60 characters..."}  
              </p>
            </div>
            <div className="app__flex">
              <input 
                className="p-text" 
                type="email" 
                placeholder="Your Email" 
                name="email" 
                value={formData.email} 
                onChange={handleChangeInput} 
                required={true}
                onBlur={()=>setEmailFocused(true)}
                //@ts-ignore
                focused={emailFocused.toString()}
                />
              <p className="form-error">
                {"Type a valid email address..."}
              </p>
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder="Your Message"
                value={formData.message}
                name="message"
                onChange={handleChangeInput}
                required={true}
                onBlur={()=>setMessageFocused(true)}
                //@ts-ignore
                focused={messageFocused.toString()}
                />
              <p className="form-error">
                {"Message is required..."}  
              </p>
            </div>
            <button 
              type="submit" 
              className="p-text" 
              disabled={loading?true:false}
            >
              {!loading ? 'Send Message' : 'Sending...'}
            </button>
          </form>
        </div>
      ) : (
        <div className="app__footer-form">
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
          <button onClick={()=>setIsFormSubmitted(false)}>
            Send another message
          </button>
        </div>
      )} */}
      <div className='app__wrapper app__flex'>
        <div className="copyright">
          <p className="p-text">{new Date().getFullYear()} - Humberto Israel López Vela</p>
        </div>
      </div>
    </>
  );
};

// export default AppWrap(
//   MotionWrap(Footer, 'app__footer'),
//   'contact',
//   'app__primarybg',
// );
export default Footer;